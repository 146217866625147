import '@/css/app.pcss';


// For the on Doc ready: Define a convenience method and use it
let ready = (callback) => {
    if (document.readyState != "loading") callback();
    else document.addEventListener("DOMContentLoaded", callback);
  }
  
  ready(() => {
    /* Do things after DOM has fully loaded */
    console.log('Doc is ready!');


    const menu = document.querySelector('.navigation');
    const menuMobile = document.querySelector('.mobile-menu');
    
    function updateMenuVisibility() {
      if (window.innerWidth <= 1024) { // You can adjust the width breakpoint as needed
        menuMobile.classList.add('visible');
        menu.classList.remove('hidden');
      } else {
        if (window.scrollY > 0) {
          menuMobile.classList.add('visible');
          menu.classList.add('hidden');
        } else {
          menuMobile.classList.remove('visible');
          menu.classList.remove('hidden');
        }
      }
    }
    
    // Call the function initially
    updateMenuVisibility();
    
    // Add event listeners for window resize and scroll
    window.addEventListener('resize', updateMenuVisibility);
    window.addEventListener('scroll', updateMenuVisibility);
    
    // Call the function on page load
    updateMenuVisibility();
    
    // Add a resize event listener to update menu visibility when the window size changes
    window.addEventListener('resize', updateMenuVisibility);
    
    
    
    
    // Get references to the elements
    const wrapper = document.getElementById('hamburger-wrapper')
    const mobileNav = document.querySelector('.mobile-menu-links');
    
    // Function to toggle the mobile navigation
    function toggleMobileNav() {
      mobileNav.classList.toggle('open');
    }
    
    // Add click event listener to the hamburger button
    wrapper.addEventListener('click', toggleMobileNav);
    wrapper.addEventListener("click", () => {
      wrapper.classList.toggle("open")
    })
    // Close mobile navigation when clicking outside the menu
    document.addEventListener('click', function (event) {
      if (!mobileNav.contains(event.target) && !wrapper.contains(event.target)) {
        mobileNav.classList.remove('open');
      }
    });





});


//Smooth Scroolling

/*
  document.addEventListener('DOMContentLoaded', function() {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth'
        });
      });
    });
  });
*/









// Scroll fade-in animation
(function () {
  let i = 0;
  let el = {};

  document.addEventListener('DOMContentLoaded', function () {
    el.targetElement = document.querySelectorAll('.js-fadeAnimation');
    el.firstDisplayOnlyFlag = false;
    // Trigger animation on DOMContentLoaded
    scrollAnimationFnc();
  }, false);

  window.addEventListener('scroll', function () {
    scrollAnimationFnc();
  }, false);

  window.addEventListener('resize', function () {
    scrollAnimationFnc();
  }, false);

  function scrollAnimationFnc() {
    el.scrollValue = window.pageYOffset || document.documentElement.scrollTop;
    el.documentHeight = document.documentElement.clientHeight;

    for (i = 0; i < el.targetElement.length; ++i) {
      el.clientRect = el.targetElement[i].getBoundingClientRect();
      el.targetPosition = el.clientRect.top + el.scrollValue;
      el.targetHeight = el.targetElement[i].offsetHeight;

      if (el.scrollValue < el.targetPosition - el.documentHeight || el.scrollValue > el.targetPosition + el.targetHeight) {

        if (!el.firstDisplayOnlyFlag) {
          el.targetElement[i].classList.remove('is-animated');
        } else {
          return false;
        }
      } else {
        el.targetElement[i].classList.add('is-animated');
      }
    }
  }



})();







